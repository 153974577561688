import axios from "axios";
import { DOMAIN, LinkMySendle } from "utils/enum";
import { api_v, base_url } from "utils/settings";
import { FORM1_SUBMITED, FORM2_SUBMITED, REGISTER_LOADING, SET_COUNTRY_LIST, SUBMIT_FORM1, SUBMIT_FORM2, SET_SUBINDUSTRY, SET_DOMAIN, SET_QUESTION, CLEAR_COMMON_QUESTION, NEX_HUB_LADING } from "./types";

export const getCountriesList = async (dispatch) => {
    var config = {
        method: 'get',
        url: `${base_url}/${api_v}countries`,
        headers: {
            'Authorization': `Bearer ${localStorage.jwtToken}`
        }
    };

    return await axios(config)
        .then(function (response) {
            dispatch({
                type: SET_COUNTRY_LIST,
                payload: response.data
            })
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getAirImportCountriesList = async (dispatch) => {
    var config = {
        method: 'get',
        url: `${base_url}/${api_v}air_import_countries`,
        headers: {
            'Authorization': `Bearer ${localStorage.jwtToken}`
        }
    };

    return await axios(config)
        .then(function (response) {
            dispatch({
                type: SET_COUNTRY_LIST,
                payload: response.data
            })
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getCountryCities = async (countryId) => {
    var config = {
        method: 'get',
        url: `${base_url}/${api_v}cities?countryId=${countryId}`,
        headers: {
            'Authorization': `Bearer ${localStorage.jwtToken}`
        }
    };

    return await axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export const getSpecializationList = async () => {
    var config = {
        method: 'get',
        url: `${base_url}/${api_v}specializations`,
        headers: {
            'Authorization': `Bearer ${localStorage.jwtToken}`
        }
    };

    return await axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getIndustryExpertiseList = async () => {
    var config = {
        method: 'get',
        url: `${base_url}/${api_v}air_industry_expertises`,
        headers: {
            'Authorization': `Bearer ${localStorage.jwtToken}`
        }
    };

    return await axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getAssociateExpertiseList = async () => {
    var config = {
        method: 'get',
        url: `${base_url}/${api_v}associate_industry_expertises`,
        headers: {
            'Authorization': `Bearer ${localStorage.jwtToken}`
        }
    };

    return await axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getVendorExpertiseList = async () => {
    var config = {
        method: 'get',
        url: `${base_url}/${api_v}vendor_industry_expertises`,
        headers: {
            'Authorization': `Bearer ${localStorage.jwtToken}`
        }
    };

    return await axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const uploadFileAsync = async (file, target) => {
    const data = new FormData();
    data.append("file", file);
    const action = `${base_url}/${api_v}${target}`;
    const config = {
        headers: {
            "content-type": "multipart/form-data"
        }
    };
    return await axios
        .post(action, data, config)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
}

export const submitForm1 = (dispatch, value) => {
    dispatch({
        type: SUBMIT_FORM1,
        payload: value
    })
}
export const form1Submited = (dispatch, value) => {
    dispatch({
        type: FORM1_SUBMITED,
        payload: value
    })
}
export const submitForm2 = (dispatch, value) => {
    dispatch({
        type: SUBMIT_FORM2,
        payload: value
    })
}
//redundency
export const resubmitForm2 = (dispatch, value) => {
    dispatch({
        type: SUBMIT_FORM2,
        payload: value
    })
}
export const form2Submited = (dispatch, value) => {
    dispatch({
        type: FORM2_SUBMITED,
        payload: value
    })
}
export const showRegisterLoading = (dispatch, value) => {
    dispatch({
        type: REGISTER_LOADING,
        payload: value
    })
}

export const getSubGroupList = () => (dispatch) => {
    let url = `/${api_v}sub_industries`;
    return axios
        .get(`${base_url}${url}`)
        .then((res) => {
            dispatch({
                type: SET_SUBINDUSTRY,
                payload: res.data["hydra:member"]
            });
        })
        .catch((err) => {
            return [];
        });
};
export const getQuestionList = () => async (dispatch) => {
    let url = `${base_url}/${api_v}questions`;
    return axios
        .get(url)
        .then((res) => {
            dispatch({
                type: SET_QUESTION,
                payload: res.data["hydra:member"]
            });
            return res.data["hydra:member"];
        })
        .catch((err) => {
            return [];
        });
};
export const setNexHubLoading = () => async (dispatch) => {
    dispatch({
        type: NEX_HUB_LADING,
        payload: true
    })
   /*  let url = `${base_url}/${api_v}questions`;
    return axios
        .get(url)
        .then((res) => {
            dispatch({
                type: NEX_HUB_LADING,
                payload: false
            })
        })
        .catch((err) => {
            return [];
        }); */
};
export const getDomainList = (domain) => (dispatch) => {
    let url = `/domains`;
    var config = {
        method: 'get',
        url: `${base_url}${url}?q=${domain}`,
        headers: {
            'X-API-KEY': 'H2GHIeSE2uLfLuvrcsW3gKHKbKP8HlGW9MA3mYgD'
        }
    };
    let defaultdata = {
        id: "1",
        domain: DOMAIN.NAP,
        logo: "https://portal.neutralairpartner.com/static/media/nap-logo.34306ae2.svg",
        coverImage: "https://neutralairpartner.com/download/login-screen.jpg",
        cssLink: "style",
    }
    let nexdata = {
        id: "1",
        domain: DOMAIN.NEX,
        logo: "https://nex-network.com/wp-content/uploads/2022/12/logo-png.png",
        coverImage: "https://nex-network.com/wp-content/uploads/2022/12/nex-network.jpg",
        cssLink: "stylenex",
    }
    if (domain === 'localhost') {
        dispatch({
            type: SET_DOMAIN,
            payload: defaultdata
        });
    } else {
        return axios(config)
            .then((res) => {
                if (res.data === null) {
                    dispatch({
                        type: SET_DOMAIN,
                        payload: defaultdata
                    });
                } else {
                    dispatch({
                        type: SET_DOMAIN,
                        payload: res.data
                    });
                }
            })
            .catch((err) => {
                return [];
            });
    }
};

export const clearCommonData = () => {
    return {
        type: CLEAR_COMMON_QUESTION
    };
};

export const loginNexHub = async () => {
    var config = {
        method: 'get',
        url: `${base_url}/${api_v}login_nex_hub`,
        headers: {
            'Authorization': `Bearer ${localStorage.jwtToken}`
        }
    };

    return await axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getUserMySendle = async () => {
    var config = {
        method: 'get',
        url: `${base_url}/${api_v}get-user-info`,
        headers: {
            'Authorization': `Bearer ${localStorage.jwtToken}`
        }
    };

    return await axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const RegitserMySendle = async (req) => {
    let modifyreq={...req}
    delete modifyreq.userSettingsId;

    if(modifyreq.hasOwnProperty('parcelLoginKey')){
        delete modifyreq.parcelLoginKey;
    }
    
    modifyreq.company.id = `${modifyreq.company.id}`;
    modifyreq.postalCode= "XXXXXX";
    modifyreq.number= "XXXXXX";

    var config = {
        headers :{
            Authorization:''
        },
        method: 'POST',
        url: `${LinkMySendle.REGISTER}`,
        data: modifyreq,
    };
    return await axios(config)
        .then(function (response) {
            return response;
            
        })
        .catch(function (error) {
            return error.response
        });
};

/* START:-  AIRPORT LISTING */
export const getAirports = async (id) => {
    var config = {
      method: "get",
      url: `${base_url}/${api_v}company/${id}/profile`,
      headers: {
        Authorization: `Bearer ${localStorage.jwtToken}`,
      },
    };
  
    return await axios(config)
      .then(function (response) {
  
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };