export const QUESTION_TYPE = {
    1: 'TEXTBOX',
    2: 'TEXTAREA',
    3: 'NUMBER',
    4: 'PASSWORD',
    5: 'EMAIL',
    6: 'PHONE_NUMBER',
    8: 'CHECKBOX',
    9: 'RADIO',
    10: 'SINGLE_SELECT',
    11: 'SINGLE_SELECT_SEARCH',
    12: 'MULTI_SELECT',
    13: 'MULTI_SELECT_SEARCH',
    14: 'SINGLE_FILE_UPLOAD',
    15: 'MULTI_FILE_UPLOAD',
    16: 'DATE_TIME',
    17: 'DATE',
    18: 'TIME',
    19: 'PERCENTAGE_SLIDER',
    20: 'NUMBER_FORMAT'
}

export const CALL_FUNCTION = [
    { id: "CountryList", name: 'CountryList' },
    { id: "CityList", name: 'CityList' },
    { id: "AirportsList", name: 'AirportsList' },
    { id: "AirlinesList", name: 'AirlinesList' }
];

export const NETWORK = {
    NAC_NEUTRAL_AIR_CONSOLIDATOR: 1,
    NAV_NEUTRAL_AVIATION_SPECIALIST: 2,
    NEX_E_COMMERCE: 3,
    NAX_24_BY_7: 4,
    NAX_FRESH: 5,
    NAF: 6,
    VENDORS: 7
}

export const QUESTION_ANSWER_TYPE = {
    RADIO: "Radio Button",
    MULTI_SELECT_SEARCH: "DropDown(Multi Select SearchAble)",
    MULTI_SELECT: "DropDown(Multi Select)",
    PHONE_NUM: "PhoneNo",
    EMAIL: "Email",
    SINGLE_SELECT: "DropDown(Single Select)",
    CHECKBOX: 'Check Box',
    SINGLE_SELECT_SEARCH: "DropDown(Single Select SearchAble)"
}

export const CALL_ACTION = {
    AIRLINE: "AirlinesList",
    AIRPORT: "AirportsList",
    COUNTRY: "CountryList",
    CITY: "CityList"
}

export const DOMAIN = {
    NEX: "portal.nex-network.com",
    NAP: "portal.neutralairpartner.com",
    STAGE_NEX: "stageport.nex-network.com",
    STAGE_NAP: "stageport.neutralairpartner.com"
}
export const LinkMySendle = {
    LOGIN:'https://parcelinternational-portal-uat.azurewebsites.net/api/v1/napuser/login',
    REGISTER:'https://parcelinternational-portal-uat.azurewebsites.net/api/v1/napuser/register'
}